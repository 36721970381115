<template>
  <v-container>
    <div v-if="team && team.t_dished">
      <h2>Laget är diskvalificerat. Kontakta oss för mer information.</h2>
    </div>
    <div v-else>
    <h2>Uppdrag</h2>
    <h5>Alla svar rättas efter rallyt och kan ge er tidsavdrag</h5>
    <v-container >
      <v-row
        no-gutters
        style="height: 100px;"
      >
        <v-col
          cols="6"
          sm="3"
          v-for="n in numMissions"
          :key="n"
        >
          <v-sheet v-if="doneMissions.includes(n)" @click="openSubmitDialog(n)" class="pa-6 ma-3 primary rounded white--text text-h6">
            {{ n }}
          </v-sheet>
          <v-sheet v-else @click="openSubmitDialog(n)" class="pa-6 ma-3 grey rounded white--text text-h6">
            {{ n }}
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="fetchingResults" class="center">
      <vue-spinner />
    </div>
    <div class="text-center" v-else>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card v-if="m_id == 18 || m_id == 21">
          <v-card-text class="pt-4">
            <h3 class="primary--text pb-2">Uppdrag #{{ m_id }} skickas in via Messenger!</h3>
          </v-card-text>
          <v-card-actions>
            <v-btn
            text
            color="primary"
            href="http://m.me/RebusrallyUtn"
            target="_blank"
            >
              Hitta oss här
            </v-btn>
            <v-btn
              text
              @click="dialog = false"
            >
              Stäng
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-text class="pt-4">
            <h2 class="primary--text pb-2">Skicka in uppdrag #{{ m_id }}</h2>
            <h4 v-if="doneMissions.includes(m_id)">Titta! Det här uppdraget har ni redan skickat in. Ni kan se ert svar <a class="text-decoration-underline" target="_blank" :href="this.getImageUrl(m_id)">här.</a></h4>
            <v-form ref="form" lazy-validation>
              <!-- Accept any image or video -->
              <v-file-input
                ref="fileupload"
                accept="image/*, video/*"
                label="Erat uppdrag"
                @change="uploadMission"
                :disabled="missionLoading"
                required
              ></v-file-input>
            </v-form>
            <div v-if="missionLoading" class="center">
              <vue-spinner />
            <p>Skickar in uppdrag...</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              @click="submitMission"
              :disabled="missionLoading"
            >
              Skicka svar
            </v-btn>
            <v-btn
              text
              @click="dialog = false"
              :disabled="missionLoading"
            >
              Stäng
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
  </v-container>
</template>

<style>
.center {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
}
</style>

<script>
import { mapGetters, mapActions } from "vuex"
import Spinner from "vue-simple-spinner"
import {notify} from '../../utils/notification'

export default {
  name: 'Missions',
  components: {
    vueSpinner: Spinner
  },
  data: () => ({
    numMissions: null,
    dialog: false,
    fileData: null,
    m_id: null, // Should be entered by user
    fileSize: null,
    doneMissions: [],
    doneMissionsData: null,
    team: null,
    fetchingResults: true,
    timer: null,
  }),
  methods: {
    ...mapActions(["sendMission", "getDoneMissions","clearNotifications", "getTeam"]),
    uploadMission(file) {
      let formData = new FormData()
      formData.append("file", file)
      this.fileSize = formData.get("file").size/(1024*1024) // Convert to MB
      this.fileData = formData
    },
    openSubmitDialog(missionNumber) {
      this.dialog = true
      this.m_id = missionNumber
    },
    closeSubmitDialog() {
      this.dialog = false
    },
    getImageUrl(missionNumber) {
      return this.doneMissionsData[missionNumber].url
    },
    async updateDoneMissions () {
      const data = await this.getDoneMissions()
      this.doneMissions = data.doneMissions
      this.doneMissionsData = data.doneMissionsData
    },
    shuffle(array) {
      // Used for gamla rallyt
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    async submitMission() {
      // Delete any previous mission ID
      this.fileData.delete("data")
      // Set new mission ID
      this.fileData.append("data", this.m_id)
      this.fileData.append("filesize", this.fileSize)
      this.sendMission(this.fileData).then(async () => {
        // Wait until this.missionLoading is false
        while(this.missionLoading) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        await this.updateDoneMissions()
        this.dialog = false
        this.fileData = null
        this.fileSize = null
        this.$refs.fileupload.reset()
        // Notification
        notify(this)
      })
    },
    async getUserTeam() {
      const team = await this.getTeam()
      this.team = team
      if(team.t_gamla_rallyt){
        this.numMissions = this.shuffle(Array.from({length: parseInt(process.env.VUE_APP_MAX_MISSIONS)}, (_, i) => i + 1))
      } else {
        this.numMissions = Array.from({length: parseInt(process.env.VUE_APP_MAX_MISSIONS)}, (_, i) => i + 1)
      }
    },
  },
  mounted() {
    this.getUserTeam()
    this.updateDoneMissions().then(() => {
      this.fetchingResults = false
    })
    this.timer = setInterval(() => {
      this.updateDoneMissions()
    }, 5000)
  },  
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: mapGetters(["notification", "missionLoading"])
}
</script>
